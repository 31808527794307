'use client';
import React from 'react';
import Image from 'next/image';
import { LinkButton } from '@/components/ui/link-button';

import { InformationBannerProps } from './informationBanner.interfaces';

import BlackRightArrowIcon from '@/assets/icons/black-right-arrow.svg';
import GreenRightArrowIcon from '@/assets/icons/green-right-arrow.svg';

export const InformationBanner: React.FC<InformationBannerProps> = ({ informationBanner }) => {
  return (
    <section
      className="px-8 pb-10 pt-44 md:pb-20 md:pt-96 2xl:pt-[500px]"
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url(${informationBanner.image.url})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
      aria-label={informationBanner.image.alt}
      title={informationBanner.image.title}
    >
      <div className="container mx-auto lg:px-8">
        <div className="bottom-auto flex flex-col gap-4 text-white md:left-0 lg:w-1/2">
          <h2 className="text-[32px] font-semibold md:text-[56px] md:leading-[60px]">{informationBanner.title}</h2>
          <p dangerouslySetInnerHTML={{ __html: `${informationBanner.description}` }} />
          <div className="flex flex-col gap-4 md:flex-row">
            <LinkButton
              className="flex gap-2 rounded-[42px] px-8 py-4 font-normal"
              href={informationBanner.firstButton.url}
              target={informationBanner.firstButton.target ? informationBanner.firstButton.target : '_blank'}
              variant="primary"
            >
              <p>{informationBanner.firstButton.text}</p>
              <Image src={BlackRightArrowIcon} className="h-4 w-4 md:h-6 md:w-6" alt="Seta preta para à direita" />
            </LinkButton>
            {informationBanner.secondButton && (
              <LinkButton
                className="flex gap-2 rounded-[42px] px-8 py-4 font-normal text-primary-40 hover:text-neutral-90"
                href={informationBanner.secondButton.url}
                target="_blank"
                variant="secondary"
              >
                <p>{informationBanner.secondButton.text}</p>
                <Image
                  src={GreenRightArrowIcon}
                  className="h-4 w-4 group-hover:to-black group-hover:text-black md:h-6 md:w-6"
                  alt="Seta preta para à direita"
                />
              </LinkButton>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
