'use client';
import React from 'react';
import Image from 'next/image';

import { LinkButton } from '@/components/ui/link-button';

import { AppProps } from './app.interfaces';

import Arrow from '@/assets/icons/white-right-arrow.svg';

export const App: React.FC<AppProps> = ({ props }) => {
  return (
    <section className="px-8 pb-10 lg:pb-20">
      <div className="container mx-auto">
        {props.sectionTitle && (
          <h2 className="my-10 text-2xl font-semibold text-white md:px-8 lg:mb-10 lg:mt-20 lg:text-5xl">
            {props.sectionTitle}
          </h2>
        )}
        <div className="flex flex-col gap-4 md:px-8 lg:flex-row">
          <div
            className="flex flex-col rounded-2xl px-6 py-4 text-white lg:w-1/2 lg:justify-end"
            style={{
              background: `linear-gradient(180deg,rgba(0, 0, 0, 0.00) 29.18%, rgba(0, 0, 0, 0.65) 60.34%), url(${props.firstCard.cardImage.url}) lightgray -12.75px 0px / cover no-repeat`,
            }}
            aria-label={props.firstCard.cardImage.alt}
            title={props.firstCard.cardImage.title}
          >
            <div className="mt-40 flex flex-col gap-2 lg:gap-4">
              <h3 className="text-3xl font-semibold leading-10 md:mt-0 md:w-full lg:text-6xl lg:font-semibold">
                {props.firstCard.cardTitle}
              </h3>
              {props.firstCard.cardDescription && (
                <p className="lg:w-2/3 lg:text-lg">{props.firstCard.cardDescription}</p>
              )}
              <LinkButton
                href={props.firstCard.button.url}
                variant="tertiary"
                className="flex w-2/3 justify-normal gap-2 px-0 md:w-full"
              >
                <p>{props.firstCard.button.text}</p>
                <Image alt="Ícone de uma seta branca apontando para a direita" src={Arrow} width={24} height={24} />
              </LinkButton>
            </div>
          </div>
          <div className="flex flex-col items-center gap-4 rounded-2xl bg-[#44CD80] px-6 pt-4 lg:w-1/2">
            <h2 className="w-2/3 text-center text-2xl font-medium">{props.secondCard.cardTitle}</h2>
            <div className="flex gap-2">
              <a href={props.secondCard.appleStoreImage.linkDownload} target="blank">
                <Image
                  src={props.secondCard.appleStoreImage.url}
                  title={props.secondCard.appleStoreImage.title}
                  alt={props.secondCard.appleStoreImage.alt}
                  width={150}
                  height={44}
                />
              </a>
              <a href={props.secondCard.googlePlayImage.linkDownload} target="blank">
                <Image
                  src={props.secondCard.googlePlayImage.url}
                  alt={props.secondCard.googlePlayImage.alt}
                  title={props.secondCard.googlePlayImage.title}
                  width={150}
                  height={44}
                />
              </a>
            </div>
            <div
              data-video-urls={`${props.secondCard.video.url.mp4}, ${props.secondCard.video.url.webm}`}
              data-autoplay
              data-loop
              data-wf-ignore
              className="max-h-[532px]"
            >
              <video
                className="max-h-[532px] rounded-t-[50px]"
                autoPlay
                loop
                muted
                playsInline
                data-wf-ignore
                preload="none"
              >
                <source src={props.secondCard.video.url.webm} type="video/webm" />
                <source src={props.secondCard.video.url.mp4} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
