import React from 'react';

import { FlagProps } from './flag.interfaces';

const Flag: React.FC<FlagProps> = ({ product }) => {
  let backgroundColor = '';
  let title = '';

  switch (product) {
    case 'totalfit':
      backgroundColor = 'bg-primary-40';
      title = ' FIT';
      break;
    case 'totalmind':
      backgroundColor = 'bg-[#BE8EFD]';
      title = ' MIND';
      break;
    case 'totalnutri':
      backgroundColor = 'bg-[#FC93D6]';
      title = ' NUTRI';
      break;
    default:
      backgroundColor = '';
      title = '';
      break;
  }

  return (
    <div className="flex">
      <p className={`justify-center rounded p-2 text-xs text-black lg:text-sm ${backgroundColor}`}>
        TOTAL<b>{title}</b>
      </p>
    </div>
  );
};

export default Flag;
