'use client';
import { usePathname } from 'next/navigation';
import React, { useEffect, useState } from 'react';

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  type CarouselApi,
} from '@/components/ui/carousel/carousel';

import { PlansData } from '@/api/graphql/interfaces/plans.interfaces';
import { PlanCard } from '@/components/plan-card/plan-card';
import { LinkButton } from '@/components/ui/link-button';

import useBetterMediaQuery from '@/hooks/media-query';

interface PlansProps {
  sectionTitle: string;
  dataPlans: PlansData;
}

export const Plans: React.FC<PlansProps> = ({ sectionTitle, dataPlans }) => {
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);
  const [count, setCount] = useState(0);

  const { matches: isMd } = useBetterMediaQuery('768px');

  const { matches: isLg } = useBetterMediaQuery('1024px');

  const { matches: isXl } = useBetterMediaQuery('1282px');

  const path = usePathname()?.includes('/mx/');

  const plans = dataPlans;

  const locale: boolean | undefined = path;

  useEffect(() => {
    if (!api) {
      return;
    }

    setCount(api.scrollSnapList().length);
    setCurrent(api.selectedScrollSnap() + 1);

    api.on('select', () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  return (
    <section id="plans" className="bg-[#F3F3F6] px-8 py-5">
      <div className="mt-8 w-full overflow-hidden lg:container lg:mx-auto">
        <div className="flex flex-col md:ml-4 md:mr-12">
          <h2 className="w-2/3 text-2xl font-semibold lg:mb-12 lg:text-5xl">{sectionTitle}</h2>
          <div className="py-5 md:ml-[17px]">
            {!isMd && !isLg && !isXl && (
              <Carousel setApi={setApi} className="w-full" opts={{ slidesToScroll: 1 }}>
                <CarouselContent>
                  {plans.plans.map((plan, index) => (
                    <CarouselItem key={index} className="md:basis-1/2 lg:basis-1/3 xl:basis-1/4">
                      <PlanCard
                        description={plan.description}
                        topics={plan.topics}
                        label={plan.label}
                        logos={plan.partnerGyms}
                        price={plan.price}
                        title={plan.name}
                        isMX={locale}
                      />
                    </CarouselItem>
                  ))}
                </CarouselContent>
                <CarouselPrevious
                  className="left-[0rem] md:left-[-1.5rem]"
                  style={{ background: 'white', borderWidth: '2px' }}
                />
                <CarouselNext
                  className="right-[0rem] md:right-[-2rem]"
                  style={{ background: 'white', borderWidth: '2px' }}
                />
              </Carousel>
            )}
            {isMd && !isLg && !isXl && (
              <Carousel setApi={setApi} className="w-full" opts={{ slidesToScroll: 2 }}>
                <CarouselContent>
                  {plans.plans.map((plan, index) => (
                    <CarouselItem key={index} className="md:basis-1/2 lg:basis-1/3 xl:basis-1/4">
                      <PlanCard
                        description={plan.description}
                        topics={plan.topics}
                        label={plan.label}
                        logos={plan.partnerGyms}
                        price={plan.price}
                        title={plan.name}
                        isMX={locale}
                      />
                    </CarouselItem>
                  ))}
                </CarouselContent>
                <CarouselPrevious
                  className="left-[0rem] md:left-[-1.5rem]"
                  style={{ background: 'white', borderWidth: '2px' }}
                />
                <CarouselNext
                  className="right-[0rem] md:right-[-2rem]"
                  style={{ background: 'white', borderWidth: '2px' }}
                />
              </Carousel>
            )}
            {isLg && !isXl && (
              <Carousel setApi={setApi} className="w-full" opts={{ slidesToScroll: 3 }}>
                <CarouselContent>
                  {plans.plans.map((plan, index) => (
                    <CarouselItem key={index} className="md:basis-1/2 lg:basis-1/3 xl:basis-1/4">
                      <PlanCard
                        description={plan.description}
                        topics={plan.topics}
                        label={plan.label}
                        logos={plan.partnerGyms}
                        price={plan.price}
                        title={plan.name}
                        isMX={locale}
                      />
                    </CarouselItem>
                  ))}
                </CarouselContent>
                <CarouselPrevious
                  className="left-[0rem] md:left-[-1.5rem]"
                  style={{ background: 'white', borderWidth: '2px' }}
                />
                <CarouselNext
                  className="right-[0rem] md:right-[-2rem]"
                  style={{ background: 'white', borderWidth: '2px' }}
                />
              </Carousel>
            )}
            {isXl && (
              <Carousel setApi={setApi} className="w-full" opts={{ slidesToScroll: 4 }}>
                <CarouselContent>
                  {plans.plans.map((plan, index) => (
                    <CarouselItem key={index} className="md:basis-1/2 lg:basis-1/3 xl:basis-1/4">
                      <PlanCard
                        description={plan.description}
                        topics={plan.topics}
                        label={plan.label}
                        logos={plan.partnerGyms}
                        price={plan.price}
                        title={plan.name}
                        isMX={locale}
                      />
                    </CarouselItem>
                  ))}
                </CarouselContent>
                <CarouselPrevious
                  className="left-[0rem] md:left-[-1.5rem]"
                  style={{ background: 'white', borderWidth: '2px' }}
                />
                <CarouselNext
                  className="right-[0rem] md:right-[-2rem]"
                  style={{ background: 'white', borderWidth: '2px' }}
                />
              </Carousel>
            )}
            <div className="text-muted-foreground py-2 text-center text-sm">
              {current} / {count}
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto flex flex-col items-center">
        <LinkButton
          className="mt-6 w-full lg:w-1/3"
          variant="primary"
          href={locale ? 'https://comunicacion.totalpass.com.mx/categorias-gyms-nuevos-planes' : '/br/planos/'}
        >
          {locale ? 'Ver todos los planes' : 'Conheça todos os planos'}
        </LinkButton>
        {locale ? (
          <>
            <p className="mt-4 text-sm">
              *El valor y los servicios de cada plan pueden variar según la negociación de tu empresa.
            </p>
            <p className="text-sm">
              *Consulta las sucursales de Smart Fit, Sport City, Sports World, Sportica y Nelson Vargas disponibles.
            </p>
            <p className="text-sm">
              *Solo podrán acceder al beneficio empleados y dependientes de empresas que contraten TotalPass.
            </p>
          </>
        ) : (
          <>
            <p className="mt-4 text-sm">
              *O valor e os serviços de cada plano podem variar de acordo com a negociação entre a sua empresa e a
              TotalPass.
            </p>
            <p className="text-sm">
              *Apenas colaboradores e dependentes de empresas que contratam a TotalPass podem acessar o benefício.
            </p>
            <p className="text-sm">¹Conteúdo reduzido nos planos TP Free e TP GO. Conteúdo completo a partir do TP1.</p>
          </>
        )}
      </div>
    </section>
  );
};
